import { CommonModule } from '@angular/common';
import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { AdminPanelProduct, ArticleDto } from '@ev-portals/dp/frontend/shared/api-client';

import { EditArticleDialogComponent } from './edit-article-dialog';
import { ProductAccordionComponent } from './product-accordion';
import { ProductAdminService } from './product-admin.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    ProductAccordionComponent,
    EditArticleDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-admin',
  templateUrl: './product-admin.component.html',
  styleUrls: ['./product-admin.component.scss'],
})
export class ProductAdminComponent {
  #productAdminService = inject(ProductAdminService);

  $selectedProductMeta = this.#productAdminService.$productMeta;
  productsStore = this.#productAdminService.productsStore;
  filterFormGroup = new FormGroup({
    searchTerm: new FormControl(''),
    withArticles: new FormControl({ checked: false }),
  });
  $filters = toSignal(this.filterFormGroup.valueChanges);
  $visibleProducts = computed(() => {
    const allProducts: AdminPanelProduct[] = this.productsStore.data() ?? [];
    const filters = this.$filters();
    const searchTerm = filters?.searchTerm?.toLowerCase() ?? '';
    const withArticles = filters?.withArticles?.checked ?? false;

    return allProducts.filter(product => {
      if (
        searchTerm &&
        !product.name.toLowerCase().includes(searchTerm) &&
        !product.id.toLowerCase().includes(searchTerm)
      ) {
        return false;
      }

      if (withArticles && !product.articles.length) {
        return false;
      }

      return true;
    });
  });

  searchLabel = 'Search';
  withArticlesLabel = 'Show only products with Articles';

  onAddArticle(product: AdminPanelProduct): void {
    this.#productAdminService.$selectedArticle.set(null);
    this.#productAdminService.$productMeta.set({
      id: product.id,
      name: product.name,
    });
  }

  onEditArticle(product: AdminPanelProduct, article: ArticleDto): void {
    this.#productAdminService.$selectedArticle.set(article);
    this.#productAdminService.$productMeta.set({
      id: product.id,
      name: product.name,
    });
  }
}
