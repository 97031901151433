import { Routes } from '@angular/router';

export const productRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./product-search').then(m => m.ProductSearchComponent),
  },
  // We maintain two routes for product details, one with slug and one without
  {
    path: ':id',
    loadComponent: () => import('./product-details').then(m => m.ProductDetailsComponent),
  },
  {
    path: ':slug/p/:id',
    loadComponent: () => import('./product-details').then(m => m.ProductDetailsComponent),
  },
];
