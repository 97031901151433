import { CommonModule } from '@angular/common';
import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AngularModule } from '@atoms/angular';
import {
  ArticleStatus,
  CreateNewArticleDto,
  ProductsApiService,
} from '@ev-portals/dp/frontend/shared/api-client';
import { FeedbackMessageService } from '@ev-portals/dp/frontend/shared/util';
import { ModalDialogComponent } from '@ev-portals/ev/frontend/ui-library';
import { numberValidator } from '@ev-portals/ev/frontend/util';
import { finalize } from 'rxjs';

import { ProductAdminService } from '../product-admin.service';
// TODO: convert to a modalDialog
@Component({
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    ModalDialogComponent,
    MatProgressBarModule,
  ],
  selector: 'dp-edit-article-dialog',
  templateUrl: './edit-article-dialog.component.html',
  styleUrls: ['./edit-article-dialog.component.scss'],
})
export class EditArticleDialogComponent implements OnInit {
  #productAdminService = inject(ProductAdminService);
  #productsApiService = inject(ProductsApiService);
  #feedbackMessageService = inject(FeedbackMessageService);

  $selectedArticle = this.#productAdminService.$selectedArticle;
  $productMeta = this.#productAdminService.$productMeta;
  $title = computed(() => (this.$selectedArticle() ? 'Edit Article' : 'Add Article'));
  $subtitle = computed(() => `${this.$productMeta()?.name} (#${this.$productMeta()?.id})`);
  $pending = signal(false);

  formGroup = new FormGroup({
    articleId: new FormControl('', [Validators.required]),
    articleName: new FormControl('', [Validators.required]),
    packagingSize: new FormControl(0, [Validators.required, numberValidator()]),
    packagingSizeUnit: new FormControl('kg', [Validators.required]),
    price: new FormControl(0, [Validators.required, numberValidator()]),
    active: new FormControl({ checked: false }),
  });

  labels = {
    articleId: 'Article ID',
    articleName: 'Article Name',
    packagingSize: 'Packaging Size',
    packagingSizeUnit: 'Packaging Size Unit',
    price: 'Price per "unit" incl. GST (18%)',
    active: 'Active',
  };

  ngOnInit(): void {
    const selectedArticle = this.$selectedArticle();
    if (selectedArticle) {
      this.formGroup.patchValue({
        articleId: selectedArticle.distributorArticleId,
        articleName: selectedArticle.name,
        packagingSize: selectedArticle.packagingSize.value,
        packagingSizeUnit: selectedArticle.packagingSize.unitOfMeasurement,
        price: selectedArticle.grossPrice.value / selectedArticle.packagingSize.value,
        active: { checked: selectedArticle.status === 'ACTIVE' },
      });
    }
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    const pricePerUnit = this.formGroup.value.price as number;
    const packagingSize = this.formGroup.value.packagingSize as number;
    const totalPrice = pricePerUnit * packagingSize;

    const articleData: Omit<CreateNewArticleDto, 'productId' | 'productName'> = {
      distributorArticleId: this.formGroup.value.articleId as string,
      name: this.formGroup.value.articleName as string,
      packagingSizeUnit: (this.formGroup.value.packagingSizeUnit as string).trim().toLowerCase(),
      packagingSizeValue: this.formGroup.value.packagingSize as number,
      priceValue: totalPrice,
      status: this.formGroup.value.active?.checked ? ArticleStatus.Active : ArticleStatus.Inactive,
    };

    const request$ = this.$selectedArticle()
      ? // Edit Mode
        this.#productsApiService.updateArticle({
          id: this.$selectedArticle()?.id as string,
          body: articleData,
        })
      : // Create Mode
        this.#productsApiService.createArticle({
          body: {
            ...articleData,
            productId: this.$productMeta()?.id as string,
            productName: this.$productMeta()?.name as string,
          },
        });

    this.$pending.set(true);
    this.formGroup.disable();

    request$
      .pipe(
        finalize(() => {
          this.$pending.set(false);
          this.onClose();
        }),
      )
      .subscribe({
        error: err => {
          console.error(err);
          this.#feedbackMessageService.showErrorMessage();
        },
        complete: () => {
          this.#feedbackMessageService.showSuccessMessage('Successfully saved');
        },
      });
  }

  onClose(): void {
    this.#productAdminService.$selectedArticle.set(null);
    this.#productAdminService.$productMeta.set(null);
    this.#productAdminService.reloadProducts();
  }
}
