import { inject, Injectable, signal } from '@angular/core';
import {
  ArticleDto,
  ArticleStatus,
  Currency,
  ProductsApiService,
} from '@ev-portals/dp/frontend/shared/api-client';
import { HttpSignalStore } from '@ev-portals/ev/frontend/util';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductAdminService {
  #productsApiService = inject(ProductsApiService);

  #getProducts$ = this.#productsApiService
    .getAdminPanelProductList()
    .pipe(map(response => response.products));

  productsStore = new HttpSignalStore(this.#getProducts$);
  $selectedArticle = signal<ArticleDto | null>(null);
  $productMeta = signal<ProductMeta | null>(null);

  reloadProducts(): void {
    this.productsStore.load(this.#getProducts$);
  }
}

export interface ProductMeta {
  id: string;
  name: string;
}

// Keep it for later debugging purposes
const mockArticle: ArticleDto = {
  id: '1',
  name: 'Article 1',
  distributorArticleId: '1',
  netPrice: {
    value: 20,
    currency: Currency.Inr,
  },
  grossPrice: {
    value: 25,
    currency: Currency.Inr,
  },
  grossPricePerUnit: {
    value: 25 / 10,
    currency: Currency.Inr,
  },
  packagingSize: {
    value: 10,
    unitOfMeasurement: 'kg',
  },
  stock: 1,
  vat: 1,
  status: ArticleStatus.Active,
};

const mockProductMeta: ProductMeta = {
  id: '1',
  name: 'Product 1',
};
