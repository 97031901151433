<ev-modal-dialog
  [$title]="$title()"
  [$subtitle]="$subtitle()"
  [$stretchMode]="true"
  (closeDialog)="onClose()"
>
  <!-- <div class="ev-h3">{{ $productMeta()?.name }}</div>
  <div class="text-gray-600 mb-4">#{{ $productMeta()?.id }}</div> -->

  <form [formGroup]="formGroup" class="grid gap-4" (submit)="onSubmit()">
    <atoms-input
      [label]="labels['articleId'] + '*'"
      formControlName="articleId"
      atomsControl2
    ></atoms-input>

    <atoms-input
      [label]="labels['articleName'] + '*'"
      formControlName="articleName"
      atomsControl2
    ></atoms-input>

    <atoms-input
      [label]="labels['packagingSize'] + '*'"
      formControlName="packagingSize"
      atomsControl2
    ></atoms-input>

    <atoms-input
      [label]="labels['packagingSizeUnit'] + '*'"
      formControlName="packagingSizeUnit"
      atomsControl2
    ></atoms-input>

    <atoms-input
      [label]="labels['price'] + '*'"
      formControlName="price"
      atomsControl2
    ></atoms-input>

    <atom-checkbox
      [label]="labels['active']"
      formControlName="active"
      atomsControl
    ></atom-checkbox>

    <div class="buttons flex justify-between flex-wrap gap-4 mt-2">
      <atom-button secondary (click)="onClose()">Cancel</atom-button>
      <atom-button-submit [disabled]="formGroup.invalid || formGroup.disabled"
        >Save</atom-button-submit
      >
    </div>
    @if ($pending()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </form>
</ev-modal-dialog>
