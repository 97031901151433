import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { AdminPanelProduct, ArticleDto } from '@ev-portals/dp/frontend/shared/api-client';
import { PricePipeModule } from '@ev-portals/ev/frontend/util';

@Component({
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, PricePipeModule],
  selector: 'dp-product-accordion',
  templateUrl: './product-accordion.component.html',
  styleUrls: ['./product-accordion.component.scss'],
})
export class ProductAccordionComponent {
  @Input() product: AdminPanelProduct;
  @Output() addArticle = new EventEmitter<void>();
  @Output() editArticle = new EventEmitter<ArticleDto>();

  columnTitles = [
    'Distributor ID',
    'Article Name',
    'Packaging Size',
    'Price incl. GST',
    'Status',
    'EDIT',
  ];
  isOpen = false;
  statusMapping: Record<ArticleDto['status'], string> = {
    ACTIVE: 'Active',
    INACTIVE: 'Disabled',
  };

  onAddArticle(event: Event): void {
    event.stopPropagation();
    this.addArticle.emit();
  }
}
